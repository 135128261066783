import React, { useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";

export const AppStoreContext = React.createContext();
const AppStoreContextProvider = (props) => {
  const cookies = new Cookies();

  const [getrecovery, setrecovery] = useState({ loading: false, data: [] });
  const [getchartofaccounts, setchartofaccounts] = useState([]);
  const [gettrialbalance, settrialbalance] = useState([]);
  const [contextLoader, setContextLoader] = useState(false);
  const [projectid, setprojectid] = useState(null);

  const updateProjectId = (val) => {
    setprojectid(val);
  };

  const recoverysheetapi = (val) => {
    setrecovery({ ...getrecovery, loading: true });
    axios({
      url:
        window.$url +
        `/Reports/Recoverysheet?Projectid=${cookies.get("ProjectId")}&date=${val}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${cookies.get("Token")}`,
      },
      method: "get",
    }).then(
      (res) => {
        setrecovery({ ...getrecovery, data: res.data.recoverydata, loading: false });
      },
      (error) => {
        setrecovery({ ...getrecovery, data: [], loading: false });
      }
    );
  };

  const chartofaccountsapi = (val) => {
    setContextLoader(true);
    axios({
      method: "Get",
      url: `${window.$url}/Headings/GetHeadings?projectid=${projectid}`,

      headers: {
        Authorization: `Bearer ${cookies.get("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        setContextLoader(false);
        setchartofaccounts(result.data);
      },
      (error) => {
        setContextLoader(false);
      }
    );
  };

  const trialbalanceapi = (val) => {
    axios({
      url: `${window.$url}/Reports/TrialBalance?Projectid=${cookies.get("ProjectId")}`,
      method: "Get",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${cookies.get("Token")}`,
      },
    }).then(
      (res) => {
        settrialbalance(res.data);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  React.useEffect(() => {
    if (projectid !== null) {
      chartofaccountsapi();
    }
  }, [projectid]);

  return (
    <AppStoreContext.Provider
      value={{
        getrecovery,
        recoverysheetapi,
        getchartofaccounts,
        chartofaccountsapi,
        trialbalanceapi,
        gettrialbalance,
        contextLoader,
        updateProjectId,
      }}
    >
      {props.children}
    </AppStoreContext.Provider>
  );
};

export default AppStoreContextProvider;
