import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Login from "./Pages/Login";
import ProjectSelection from "./Pages/ProjectSelection";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import AppStoreContextProvider from "./Pages/context/contextstore";

import { createStore, applyMiddleware, compose } from "redux";
import AllReducers from "./Pages/reducer/allreducers";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import ProjectStoreContextProvider from "./Pages/context/projectdetailstore";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UnderMaintenancePage from "./UnderMaintenance/UnderMaintenancePage";
import MonthlyAlertContextProvider from "./ContextApi/MonthlyAlert";
const composeEnhancers =
  (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const enhancer = composeEnhancers(applyMiddleware(thunk));
const store = createStore(AllReducers, enhancer);

//window.$url = "http://192.168.0.125:4000/api";
 window.$url = "https://accounts.aiksol.com/api";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Switch>
          <ProjectStoreContextProvider>
            <AppStoreContextProvider>
              <MonthlyAlertContextProvider>
                <Route exact path="/" component={Login} />
                <Route exact path="/ProjectSelection" component={ProjectSelection} />
                <Route exact path="/Dashboard" component={App} />
              </MonthlyAlertContextProvider>

              {/* <Route exact path="/" component={UnderMaintenancePage} /> */}
              <ToastContainer autoClose={3000} />
            </AppStoreContextProvider>
          </ProjectStoreContextProvider>
        </Switch>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
